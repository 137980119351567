import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { AppContainer } from 'react-hot-loader'
import { ConfigProvider } from 'antd'
import zhTW from 'antd/lib/locale-provider/zh_TW'
import moment from 'moment'
import 'moment/locale/zh-tw'

import { initializeStore } from './js/lib/redux/store'
import { register } from './serviceWorker'
import App from './App'

import './styles/antd-theme.less'
import './styles/main.scss'

moment.locale('zh-tw')

const store = initializeStore()
const render = () => {
  console.log(`%c 目前版號 %c ${process.env.NPM_PACKAGE_VERSION} `,
    'background:rgb(043, 063, 087); padding: 1px; border-radius: 5px 0 0 5px; color: rgb(243, 245, 246); font-size: 20px;',
    'background:rgb(212, 225, 244); padding: 1px; border-radius: 0 5px 5px 0; color: rgb(043, 063, 087); font-size: 20px;')

  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer>
          <ConfigProvider autoInsertSpaceInButton={false} locale={zhTW}>
            <App />
          </ConfigProvider>
        </AppContainer>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
register()
render()

if (module.hot) {
  module.hot.accept('./App', render)
}
