import React from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { hot } from 'react-hot-loader'
import Loadable from 'react-loadable'

import AuthApi from './js/lib/api/auth'

const loading = () => null
const Auth = Loadable({ loader: () => import('./js/views/Auth'), loading })
const Home = Loadable({ loader: () => import('./js/views/Home'), loading })

function App (props) {
  const isAuthenticated = AuthApi.getAuthentication()

  return isAuthenticated ? (
    <Switch>
      <Route strict sensitive path='/home' component={Home} />
      <Redirect push from='/' to='/home' />
    </Switch>
  ) : (
    <Switch>
      <Route strict sensitive path='/auth' component={Auth} />
      <Redirect push from='/' to='/auth' />
    </Switch>
  )
}

export default hot(module)(withRouter(App))
