import Bowser from 'bowser'
// import { API_LOGOUT } from './mock'
import Denormalizer from './denormalizer'
import Normalizer from './normalizer'

import AuthApi from '../auth'
import Service from '../service'

const parser = Bowser.getParser(window.navigator.userAgent)
const { browser, os, platform } = parser.getResult()

// Document: http://10.1.1.54:5203/swagger/index.html
class User {
  static EMPLOYEE_ID = 'EMPLOYEE_ID'

  static setEmployeeId (employeeId) {
    return new Promise((resolve, reject) => localStorage.setItem(User.EMPLOYEE_ID, employeeId.trim()))
  }

  static removeEmployeeId () {
    return new Promise((resolve, reject) => localStorage.removeItem(User.EMPLOYEE_ID))
  }

  static getEmployeeId () {
    return localStorage.getItem(User.EMPLOYEE_ID)
  }

  static login ({ account, password } = {}) {
    const service = new Service(
      {
        url: `/User/Login`,
        method: 'POST',
        data: {
          account,
          password,
          // '01' Web(後台) / '02' Web(前台) / '03' APP
          platform: '01',
          deviceType: browser.name,
          version: browser.version,
          deviceId: `${platform.type}-${os.name}-${os.version}-${browser.name}-${browser.version}`,
        },
      },
      {
        withAccessToken: false,
        denormalizer: Denormalizer.Login,
      }
    )

    return service.callApi().then(response => {
      AuthApi.setAccessToken(response.data.token)
      User.setEmployeeId(account)

      return response
    })
  }

  // 登出不用 call api，直接清掉 token 就好
  static logout () {
    return new Promise((resolve, reject) => {
      AuthApi.removeAccessToken()
      User.removeEmployeeId()
    })
  }

  static fetchUserDetail ({ employeeId } = {}) {
    const service = new Service(
      {
        url: `/User/${employeeId}`,
        method: 'GET',
      },
      {
        withAccessToken: true,
        normalizer: Normalizer.UserDetail,
      }
    )

    return service.callApi()
  }

  static fetchUserList ({ withAll = true, employeeId, employeeName, jobDescription, pagingIndex, pagingSize = 10 } = {}) {
    const service = new Service(
      {
        url: `/User`,
        method: 'GET',
        params: {
          withAll,
          employeeId,
          employeeName,
          jobDescription,
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchUserList,
        normalizer: Normalizer.UserList,
      }
    )

    return service.callApi()
  }

  static fetchUserRoleList ({ employeeId, departmentId, teamId, pagingIndex = 0, pagingSize = 0 } = {}) {
    const service = new Service(
      {
        url: `/User/role/${employeeId}`,
        method: 'GET',
        params: {
          departmentId,
          teamId,
          pagingIndex,
          pagingSize,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.FetchUserRoleList,
        normalizer: Normalizer.RoleList,
      }
    )

    return service.callApi()
  }

  static deleteUserRole ({ employeeId, departmentId, teamId, roleIdList } = {}) {
    const service = new Service(
      {
        url: `/User/role/${employeeId}`,
        method: 'DELETE',
        data: {
          departmentId,
          teamId,
          roleIdList,
        },
      },
      {
        withAccessToken: true,
        denormalizer: Denormalizer.DeleteUserRole,
      }
    )

    return service.callApi()
  }
}

export default User
