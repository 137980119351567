import Service from '../service'

class Normalizer {
  static OrganizationItem ({ deptId, deptName, teamId, teamName }) {
    return {
      departmentId: deptId,
      departmentName: deptName,
      teamId,
      teamName,
    }
  }

  static OrganizationList (payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.OrganizationItem)
  }

  static deputySecretaryGeneral ({ empId, empName }) {
    return {
      employeeId: String(empId),
      employeeName: empName,
    }
  }

  static UnitOrganizationItem ({ deptId, deptName, deputySecretaryGeneral }) {
    return {
      departmentId: deptId,
      departmentName: deptName,
      deputySecretaryGeneral: deputySecretaryGeneral ? Normalizer.deputySecretaryGeneral(deputySecretaryGeneral) : null,
    }
  }

  static UnitOrganizationList (payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.UnitOrganizationItem)
  }

  static AbroadUnitOrganizationItem ({ deptId, deptName }) {
    return {
      departmentId: deptId,
      departmentName: deptName,
    }
  }

  static AbroadUnitOrganizationList (payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.AbroadUnitOrganizationItem)
  }

  static AbroadUnitItem ({ deptId, deptName }) {
    return {
      departmentId: deptId,
      departmentName: deptName,
    }
  }

  static AbroadUnitList (payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.AbroadUnitItem)
  }
}

export default Normalizer
