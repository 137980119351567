class Denormalizer {
  static Login ({ account, password, platform, deviceType, version, deviceId }) {
    return {
      account,
      password,
      platform,
      deviceType,
      version,
      deviceId,
    }
  }

  static FetchUserList ({ withAll, employeeId, employeeName, jobDescription, pagingIndex, pagingSize }) {
    return {
      all: withAll,
      empId: employeeId,
      empName: employeeName,
      jobDesc: jobDescription,
      pagingIndex,
      pagingSize,
    }
  }

  static FetchUserRoleList ({ teamId, departmentId, pagingIndex, pagingSize }) {
    return {
      teamId,
      deptId: departmentId,
      pagingIndex,
      pagingSize,
    }
  }

  static DeleteUserRole ({ teamId, departmentId, roleIdList }) {
    return {
      teamId,
      deptId: departmentId,
      removeRoles: roleIdList,
    }
  }
}

export default Denormalizer
