// Separate the thunks from the action creators
// If the operation only dispatches a single action — forward the action creator function.
// If the operation uses a thunk, it can dispatch many actions and chain them with promises.
import * as actions from './actions'
import UserApi from '../../../api/user'

export const setInfo = actions.setInfo

export const fetchAccountInfo = ({ employeeId }) => (dispatch, getStatus) => {
  return UserApi.fetchUserDetail({ employeeId }).then(response => {
    dispatch(actions.setInfo({ info: response.data }))

    return response.data
  })
}
