import { createReducer } from 'redux-create-reducer'
import * as actionTypes from './actionTypes'

const initialState = {
  info: null,
}

const user = createReducer(initialState, {
  [actionTypes.SET_INFO]: (state, { payload }) => ({ ...state, info: payload.info }),
})

export default user
