import Service from '../service'
import OrganizationNormalizer from '../organization/normalizer'

class Normalizer {
  static UserItem ({ empId, empName, jobDesc, organization }) {
    return {
      employeeId: empId,
      employeeName: empName,
      jobDescription: jobDesc,
      organizations: Service.normalizeList(organization, OrganizationNormalizer.OrganizationItem),
    }
  }

  static UserDetail ({ empId, empName, email, jobDesc, ext, managers, status, organization, requestDate }) {
    return {
      employeeId: empId,
      employeeName: empName,
      email,
      jobDescription: jobDesc,
      extensionNumber: ext,
      managers,
      organizations: Service.normalizeList(organization, OrganizationNormalizer.OrganizationItem),
      status,
      requestDate,
    }
  }

  static UserList (payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.UserItem)
  }

  static RoleItem ({ roleId, roleName }) {
    return {
      roleId: String(roleId),
      roleName,
    }
  }

  static RoleList (payload) {
    return Service.normalizePayloadWithPagination(payload, Normalizer.RoleItem)
  }
}

export default Normalizer
